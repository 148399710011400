import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import Auth from "../../utils/auth";
import { useNavigate, useParams } from "react-router-dom";
import { QUERY_EVENT_BY_ID } from "../../utils/queries";

const EventTracker = () => {
  const { eventId } = useParams();
  const { loading, data, error } = useQuery(QUERY_EVENT_BY_ID, {
    variables: { eventId },
    onCompleted: (data) => console.log("Query completed:", data),
    onError: (error) => console.error("Query error:", error),
  });

  const navigate = useNavigate();
  const adminLoggedIn = Auth.loggedIn();
  if (!adminLoggedIn) {
    navigate("/login");
    return null; // Prevent further rendering
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const eventById = data?.eventById;

  // Create a map to count tickets per promo ID
  const promoTicketCount = {};
  eventById.ticketsSold.forEach((ticket) => {
    const promoId = ticket.promo?.code; // Assuming promo code is stored in the ticket
    if (promoId) {
      promoTicketCount[promoId] = (promoTicketCount[promoId] || 0) + 1;
    }
  });

  // Map promo IDs to their names and counts
  const promoCounts = eventById.promos.reduce((acc, promo) => {
    acc[promo._id] = {
      ...promo,
      count: promoTicketCount[promo._id] || 0,
    };
    return acc;
  }, {});

  return (
    <div className="p-4 min-h-screen flex flex-col items-center">
      <h1 className="text-2xl font-bold">{eventById.title}</h1>
      <h2 className="text-xl font-semibold mt-4">Tickets Sold:</h2>
      <p className="text-lg">{eventById.ticketsSold.length}</p>
      {eventById.ticketsSold.map(t=>(<p>{t.name}</p>))
      }
      <h3 className="text-xl font-semibold mt-4">Promos:</h3>
      <div className="w-full max-w-md">
        {eventById.promos.map((promo) => (
          <div
            key={promo._id}
            className="p-4 mb-2 bg-gray-100 rounded shadow text-black"
          >
            <h4 className="text-lg font-medium">{promo.code}</h4>
            <p>Tickets Sold: {promoCounts[promo._id]?.count || 0}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventTracker;
