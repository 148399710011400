import gql from "graphql-tag";

const QUERY_FUTURE_EVENTS = gql`
  query FutureEvents {
    futureEvents {
      _id
      date
      doorTime
      showTime
      venue
      address
      title
      description
      imgUrl
      tiers {
        level
        price
        quantity
      }
      externalTicketLink
      createdAt
      updatedAt
    }
  }
`;

const QUERY_EVENT_BY_ID = gql`
  query EventById($eventId: ID!) {
    eventById(eventId: $eventId) {
      _id
      date
      doorTime
      showTime
      venue
      address
      title
      description
      imgUrl
      tiers {
        level
        price
        quantity
      }
      promos {
        _id
        code
        discount
        quantity
      }
      ticketsSold {
        name
        email
        barcode
        entered
        paymentConfirmation
        createdAt
      }
      externalTicketLink
      ticketDeliveryOptions {
        method
        description
        price
        recommended
      }
      createdAt
      updatedAt
    }
  }
`;

const QUERY_BLOG_POSTS = gql`
  query BlogPosts {
    blogPosts {
      _id
      author
      body
      createdAt
      image
      summary
      title
      updatedAt
    }
  }
`;

const QUERY_BLOG_POST_BY_ID = gql`
  query BlogPosts($postId: ID!) {
    blogPostById(postId: $postId) {
      _id
      author
      body
      createdAt
      image
      summary
      title
      updatedAt
    }
  }
`;

const PAY = gql`
  query Query($amount: Float!, $description: String) {
    createCheckoutSession(amount: $amount, description: $description)
  }
`;

const QUERY_MY_TICKETS = gql`
  query MyTickets($email: String, $paymentConfirmation: String) {
    myTickets(email: $email, paymentConfirmation: $paymentConfirmation) {
      _id
      name
      email
      barcode
      paymentConfirmation
      entered
      event {
        _id
        date
        doorTime
        showTime
        venue
        address
        title
        description
        imgUrl
        tiers {
          level
          price
          quantity
        }
        promos {
          _id
          code
          discount
          quantity
        }
        ticketsSold {
          _id
        }
        externalTicketLink
        ticketDeliveryOptions {
          method
          price
          description
          recommended
        }
        createdAt
        updatedAt
      }
      tier {
        quantity
        price
        level
      }
      createdAt
      updatedAt
      promo {
        _id
        code
        discount
        quantity
      }
      deliveryMethod {
        description
        method
        price
        recommended
      }
    }
  }
`;

const TICKETS_BY_BARCODE = gql`
  query TicketsByBarcode($barcode: String!) {
    ticket(barcode: $barcode) {
      _id
      email
      entered
      paymentConfirmation
      event {
        title
        date
      }
    }
  }
`;

const GET_INVASION_ARTISTS = gql`
  query GetInvasionArtists {
    getInvasionArtists {
      _id
      name
      bio
      image
      logo
    }
  }
`;

export {
  QUERY_FUTURE_EVENTS,
  QUERY_EVENT_BY_ID,
  QUERY_BLOG_POSTS,
  QUERY_BLOG_POST_BY_ID,
  PAY,
  QUERY_MY_TICKETS,
  TICKETS_BY_BARCODE,
  GET_INVASION_ARTISTS,
};
