import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_FUTURE_EVENTS } from "../../utils/queries";
import Auth from "../../utils/auth";
import { useNavigate, Link } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const adminLoggedIn = Auth.loggedIn();
  if (!adminLoggedIn) {
    navigate("/login");
  }

  const { loading, data, error } = useQuery(QUERY_FUTURE_EVENTS, {
    onCompleted: (data) => console.log("Query completed:", data),
    onError: (error) => console.error("Query error:", error),
  });

  if (loading) return <h2 className="text-white">...loading</h2>;
  if (error) return <h2 className="text-white">Error: {error.message}</h2>;

  if (!loading) {
    const events = data?.futureEvents || [];

    // Debugging: Log the events array
    console.log("Events data:", events);

    return (
      <div className="p-4 min-h-screen flex flex-col items-center">
        <Link to="/" onClick={() => Auth.logout()} className="text-white">
          LOGOUT
        </Link>
        <h1 className="text-white text-3xl mt-4">DASHBOARD</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 p-5">
          {events.length > 0 ? (
            events.map((event) => (
              <div
                style={{ backgroundImage: `URL(${event.imgUrl})` }}
                className="w-screen h-40 hover:cursor-pointer"
                onClick={() => {
                  navigate(`/admin/event/${event._id}`);
                }}
              >
                <h1 className="text-3xl">{event.title}</h1>
              </div>
            ))
          ) : (
            <p className="text-white">No upcoming events available</p>
          )}
        </div>
      </div>
    );
  }
};

export default AdminDashboard;
