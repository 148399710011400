import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "@apollo/client";
import { PAY } from "../../utils/queries";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51Pb7DZ2NwjZjFvS7q1iMJ0xruDkYj25NmBDhzDH6EmyfnaDN9i8rxZ0uTVf3P7KWAH6s6NTE8gi36mMeT4QPkTT300l6NiwCT4"
);

const LiabilityModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">
          Liability Waiver and Release Agreement
        </h2>
        <p className="text-sm text-gray-700 mb-4">
          PLEASE READ CAREFULLY. THIS IS A LEGAL DOCUMENT THAT AFFECTS YOUR
          RIGHTS. BY PURCHASING A TICKET AND ATTENDING THE EVENT, YOU
          ACKNOWLEDGE AND AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS
          AGREEMENT.
          <br />
          <br />
          1. Acknowledgment of Risk I, the ticket holder, acknowledge that my
          participation in the Invasion Festival ("the Event") involves inherent
          risks, including, but not limited to, injury, illness, or death. I
          understand that these risks cannot be completely eliminated regardless
          of the care taken to avoid injuries.
          <br />
          <br />
          2. Assumption of Risk I voluntarily choose to participate in the Event
          and assume all such risks, both known and unknown, even if arising
          from the negligence of the Event organizers, affiliates, or others,
          and assume full responsibility for my participation.
          <br />
          <br />
          3. Release of Liability In consideration of being permitted to
          participate in the Event, I, on behalf of myself, my heirs, personal
          representatives, and assigns, hereby release, waive, discharge, and
          covenant not to sue Pew Pew Productionz, LLC, its affiliates,
          employees, agents, contractors, and any other participants ("the
          Released Parties") from any and all claims, demands, damages, costs,
          expenses, actions, and causes of action, whether in law or equity,
          that may arise out of or relate to any loss, damage, or injury,
          including death, that may be sustained by me or my property, whether
          caused by the negligence of the Released Parties or otherwise, while
          participating in the Event or while on the premises where the Event is
          conducted.
          <br />
          <br />
          4. Indemnification I agree to indemnify and hold harmless the Released
          Parties from any loss, liability, damages, costs, or expenses arising
          from my participation in the Event.
          <br />
          <br />
          5. Medical Consent and Insurance I acknowledge that I am responsible
          for my own medical expenses and that I have my own medical and health
          insurance coverage. I consent to receive medical treatment deemed
          advisable in the event of injury, accident, and/or illness during the
          Event.
          <br />
          <br />
          6. Governing Law This Agreement shall be governed by and construed in
          accordance with the laws of the State of CO, without regard to its
          conflict of laws principles.
          <br />
          <br />
          7. Severability If any term or provision of this Agreement is held to
          be invalid, illegal, or unenforceable, the remaining terms and
          provisions will continue in full force and effect.
          <br />
          <br />
          8. Acknowledgment of Understanding I have read this Agreement, fully
          understand its terms, understand that I have given up substantial
          rights by purchasing a ticket, and have agreed to it freely and
          voluntarily without any inducement, assurance, or guarantee being made
          to me.
          <br />
          <br />I ACKNOWLEDGE THAT I HAVE READ THIS WAIVER AND RELEASE OF
          LIABILITY, FULLY UNDERSTAND ITS TERMS, AND UNDERSTAND THAT I AM GIVING
          UP SUBSTANTIAL RIGHTS, INCLUDING MY RIGHT TO SUE. I ACKNOWLEDGE THAT I
          AM SIGNING THE AGREEMENT FREELY AND VOLUNTARILY, AND INTEND MY
          PURCHASE OF THE TICKET TO BE A COMPLETE AND UNCONDITIONAL RELEASE OF
          ALL LIABILITY TO THE GREATEST EXTENT ALLOWED BY LAW.
        </p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Pay = ({ event, tier, quantity, selectedDeliveryMethod }) => {
  const [paymentAmount, setPaymentAmount] = useState(
    event.tiers[tier - 1].price * quantity +
      event.ticketDeliveryOptions[selectedDeliveryMethod].price
  );
  const [ready, setReady] = useState("false");
  const [clientSecret, setClientSecret] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [foundPromo, setFoundPromo] = useState({
    _id: "",
    quantity: 0,
    code: "",
    discount: 0,
  });
  const [showPromoForm, setShowPromoForm] = useState(true);
  const [showCustomerForm, setShowCustomerForm] = useState(true);
  const [showPromoWrong, setShowPromoWrong] = useState(false);
  const [showPromoInfo, setShowPromoInfo] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [waiverAccepted, setWaiverAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let taxes = paymentAmount * 0.0881;
  let fees = paymentAmount * 0.029 + 2.3;
  if (paymentAmount === 0) {
    taxes = 0;
    fees = 0;
  }
  const finalAmount = paymentAmount + taxes + fees;

  const handleChange = (event) => {
    setPromoCode(event.target.value);
  };

  const {
    loading: payLoading,
    data: payData,
    error: payError,
  } = useQuery(PAY, {
    variables: {
      amount: Math.round((paymentAmount + taxes + fees) * 100) / 100,
      description: `${event.date}, ${event.title}, ${name}, ${email} tickets`,
    },
    skip: ready === "false",
    onCompleted: (data) => {
      console.log(data);
      setClientSecret(data.createCheckoutSession);
      setReady("false");
    },
  });

  let promo = {};
  const checkPromoCode = () => {
    promo = event.promos.find((p) => p.code === promoCode);
    console.log(promo);
    if (promo && promo.quantity !== 0) {
      setFoundPromo({
        _id: promo._id,
        code: promo.code,
        discount: promo.discount,
        quantity: promo.quantity,
      });

      setPaymentAmount(paymentAmount - promo.discount);
      setShowPromoWrong(false);
      setShowPromoInfo(true);
      setShowPromoForm(false);
    } else {
      setShowPromoWrong(true);
    }
  };

  const appearance = {
    theme: "night",
    labels: "floating",
  };

  const options = {
    clientSecret,
    appearance,
  };

  console.log(paymentAmount, fees, taxes);
  return (
    <div
      className="Pay flex justify-center items-center h-screen bg-cover bg-center"
      style={{ backgroundImage: `url('/path/to/earth-image.jpg')` }}
    >
      <div className="bg-gray-900 bg-opacity-80 p-8 mt-32 rounded-lg w-full md:w-3/4 lg:w-1/2 xl:w-1/3 border border-yellow-500 shadow-lg mt-30">
        <h1 className="text-3xl text-center mb-6 text-white">CHECKOUT</h1>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col bg-white bg-opacity-90 p-4 rounded-lg shadow-md">
            <div className="flex justify-between">
              <p className="mb-2 text-gray-800">Ticket Price:</p>
              <p className="mb-2 text-gray-800">
                {" "}
                ${event.tiers[tier - 1].price.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="mb-2 text-gray-800">Quantity:</p>
              <p className="mb-2 text-gray-800"> {quantity}</p>
            </div>
            <div className="flex justify-between">
              <p className="mb-2 text-gray-800">Delivery Price:</p>
              <p className="mb-2 text-gray-800">
                $
                {event.ticketDeliveryOptions[
                  selectedDeliveryMethod
                ].price.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between border-b border-black">
              <p className="mb-2 text-gray-800">Subtotal:</p>
              <p className="mb-2 text-gray-800">${paymentAmount.toFixed(2)}</p>
            </div>
            <div className="flex justify-between">
              <p className="mb-2 text-gray-800">Discount:</p>
              <p className="mb-2 text-gray-800">
                -${foundPromo.discount.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="mb-2 text-gray-800">Sales Tax:</p>
              <p className="mb-2 text-gray-800">${taxes.toFixed(2)}</p>
            </div>
            <div className="flex justify-between  border-b border-black">
              <p className="mb-2 text-gray-800">Fees:</p>
              <p className="mb-2 text-gray-800">${fees.toFixed(2)}</p>
            </div>
            <div className="flex justify-between">
              <p className="mb-2 text-gray-800">Order Total:</p>
              <p className="mb-2 text-gray-800">
                ${(paymentAmount + taxes + fees).toFixed(2)}
              </p>
            </div>
            {showPromoForm ? (
              <div className="flex">
                <input
                  type="text"
                  placeholder="Promo Code"
                  className="w-full rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500 px-3"
                  value={promoCode}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={checkPromoCode}
                >
                  Apply
                </button>
              </div>
            ) : (
              <div className="flex justify-between">
                <p className="mb-2 text-gray-800">Promo Code:</p>
                <p className="mb-2 text-gray-800">{foundPromo.code}</p>
              </div>
            )}
            {showPromoWrong && (
              <div className="text-red-500">Invalid Promo Code</div>
            )}
            {showPromoInfo && (
              <div className="text-green-500">
                {foundPromo.code} Applied Successfully
              </div>
            )}
          </div>
          {showCustomerForm && (
            <form className="flex flex-col space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-white"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  id="waiver"
                  className="mr-2"
                  checked={waiverAccepted}
                  onChange={() => setWaiverAccepted(!waiverAccepted)}
                />
                <label htmlFor="waiver" className="text-white">
                  I accept the{" "}
                  <button
                    type="button"
                    className="underline text-blue-500 hover:text-blue-600"
                    onClick={() => setIsModalOpen(true)}
                  >
                    liability waiver
                  </button>
                  .
                </label>
              </div>
              <button
                type="button"
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={() => {
                  if (name && email && waiverAccepted) {
                    setShowCustomerForm(false);
                    setReady("true");
                  }
                }}
                disabled={!name || !email || !waiverAccepted}
              >
                Proceed to Checkout
              </button>
            </form>
          )}
          {!showCustomerForm && clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                amount={paymentAmount}
                taxes={Math.round(taxes * 100) / 100}
                fees={Math.round(fees * 100) / 100}
                orderTotal={paymentAmount + taxes + fees}
                name={name}
                email={email}
                tier={tier}
                quantity={quantity}
                deliveryMethod={selectedDeliveryMethod}
                event={event}
                promoId={foundPromo._id}
              />
            </Elements>
          )}
        </div>
      </div>
      <LiabilityModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Pay;
