import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

//import Auth from "./utils/auth";
import "./App.css";
import Home from "./components/Home";
import LoginForm from "./components/forms/LoginForm";
import Invasion from "./components/invasion/Invasion";
import InvasionGetInvolved from "./components/invasion/InvasionGetInvolved";
import InvasionLineup from "./components/invasion/InvasionLineup";
import InvasionInformation from "./components/invasion/InvasionInformation";
import CreateInvasionArtistForm from "./components/forms/CreateInvasionArtistForm";
import Events from "./components/Events";
import Event from "./components/Event";
import About from "./components/About";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import Merch from "./components/Merch";
import Gallery from "./components/Gallery";
import CreateEventForm from "./components/forms/CreateEventForm";
import UpdateEventForm from "./components/forms/UpdateEventForm";
import CreateBlogPost from "./components/forms/CreateBlogPostForm";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import BuyTickets from "./components/eventCheckout/BuyTickets";
import PaymentSuccess from "./components/eventCheckout/PaymentSuccess";
import Tickets from "./components/Tickets";
import ScanTicket from "./components/admin/ScanTicket";
import AdminDashboard from "./components/admin/AdminDashboard";
import EventTracker from "./components/admin/EventTracker";
console.log(createUploadLink);
// var httpLink = createHttpLink({
//   uri: "/graphql",
// });
const uploadLink = createUploadLink({ uri: "/graphql" });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("id_token");
  const adminLoggedIn = localStorage.getItem("adminLoggedIn");
  const clientId = localStorage.getItem("clientId");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      adminLoggedIn: adminLoggedIn,
      clientId: clientId,
    },
  };
});
const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <NavBar />
        <main className="z-10 mt-24">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/Invasion" element={<Invasion />} />
            <Route
              path="/Invasion/getInvolved"
              element={<InvasionGetInvolved />}
            />
            <Route path="/Invasion/lineup" element={<InvasionLineup />} />
            <Route
              path="/Invasion/information"
              element={<InvasionInformation />}
            />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:eventId" element={<Event />} />
            <Route
              path="/buy/:eventId/:tier/:quantity"
              element={<BuyTickets />}
            />
            <Route
              path="/paymentSuccess/:paymentConfirmation"
              element={<PaymentSuccess />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:postId" element={<BlogPost />} />
            <Route path="/merch" element={<Merch />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/createEvent" element={<CreateEventForm />} />
            <Route
              path="/createInvasionArtist"
              element={<CreateInvasionArtistForm />}
            />
            <Route path="/updateEvent/:eventId" element={<UpdateEventForm />} />
            <Route path="/createBlogPost" element={<CreateBlogPost />} />
            <Route path="/tickets/:paymentConfirmation" element={<Tickets />} />
            <Route path="/tickets/" element={<Tickets />} />
            <Route path="/admin/scan" element={<ScanTicket />} />
            <Route path="/admin/event/:eventId" element={<EventTracker />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

export default App;
