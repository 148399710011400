import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { CREATE_TICKETS, DELETE_TICKETS } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
// import "./checkoutForm.css";

export default function CheckoutForm({
  tier,
  quantity,
  deliveryMethod,
  name,
  email,
  event,
  promoId,
  paymentAmount,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createTickets] = useMutation(CREATE_TICKETS);
  const [deleteTickets] = useMutation(DELETE_TICKETS);
  const navigate = useNavigate();

  const createFreeTickets = async () => {
    const paymentConfirmation = uuidv4();
    let variables = {
      tier: Number(tier),
      quantity: Number(quantity),
      deliveryMethod,
      name,
      email,
      eventId: event._id,
      promoId,
      paymentConfirmation,
    };
    if ((promoId = "")) {
      variables = {
        tier: Number(tier),
        quantity: Number(quantity),
        deliveryMethod,
        name,
        email,
        eventId: event._id,
        paymentConfirmation,
      };
    }

    let tix = await createTickets({
      variables,
    });

    navigate("/paymentSuccess/" + paymentConfirmation);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // if(!clientSecret && paymentAmount===0){
    //   createFreeTickets();
    // }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const paymentConfirmation = uuidv4();
    try {
      let variables = {
        tier: Number(tier),
        quantity: Number(quantity),
        deliveryMethod,
        name,
        email,
        eventId: event._id,
        promoId,
        paymentConfirmation,
      };
      if ((promoId = "")) {
        variables = {
          tier: Number(tier),
          quantity: Number(quantity),
          deliveryMethod,
          name,
          email,
          eventId: event._id,
          paymentConfirmation,
        };
      }
      console.log(variables);
      let tix = await createTickets({
        variables,
      });
      console.log(tix);
      tix = tix.data.createTickets.map((t) => t._id);
      console.log(tix);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url:
            "https://pewpewprodz.com/paymentSuccess/" + paymentConfirmation,
        },
      });

      if (error) {
        await deleteTickets({
          variables: {
            ids: tix,
            tier: Number(tier),
          },
        });
      }

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="btn w-full mt-3"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
