import React from "react";
import { motion } from "framer-motion";
import { useQuery } from "@apollo/client";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { QUERY_BLOG_POSTS, QUERY_FUTURE_EVENTS } from "../utils/queries";
import { Link } from "react-router-dom";

const Home = () => {
  const {
    loading: loadingEvents,
    data: dataEvents,
    error: errorEvents,
  } = useQuery(QUERY_FUTURE_EVENTS, {
    onCompleted: (data) => console.log("Query completed:", data),
    onError: (error) => console.error("Query error:", error),
  });

  const {
    loading: loadingBlogs,
    data: dataBlogs,
    error: errorBlogs,
  } = useQuery(QUERY_BLOG_POSTS, {
    onCompleted: (data) => console.log("Query completed:", data),
    onError: (error) => console.error("Query error:", error),
  });

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}.${day}.${year}`;
  };

  if (loadingEvents || loadingBlogs) {
    return <h2>...loading</h2>;
  }

  if (errorEvents || errorBlogs) {
    return <h2>Error: {errorEvents?.message || errorBlogs?.message}</h2>;
  }

  const events = dataEvents?.futureEvents ? [...dataEvents.futureEvents] : [];
  const blogPosts = dataBlogs?.blogPosts ? [...dataBlogs.blogPosts] : [];

  // Sort the events by date
  events.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div className="bg-black text-white p-5 flex flex-col items-center">
      {/* Spotlight Carousel */}
      <motion.div
        initial={{ opacity: 0, y: -0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="w-full max-w-5xl z-[0]" // Set z-index lower here
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          autoplay={true}
          loop
          pagination={{ clickable: true }}
          className="h-96 ease-in-out duration-1000 hover:h-[500px] border border-warning shadow-lg shadow-error rounded rounded-2xl"
        >
          {events.map((slide, index) => (
            <SwiperSlide key={index} className="relative ">
              <Link
                to={
                  slide.title === "Invasion"
                    ? "/invasion"
                    : slide.externalTicketLink
                    ? slide.externalTicketLink
                    : "/events/" + slide._id
                }
              >
                <img
                  src={slide.imgUrl}
                  alt={slide.title}
                  className="object-cover object-top w-full h-full "
                />
                <div className="bg-black bg-opacity-20 hover:bg-opacity-0 py-5 absolute inset-0 flex flex-col justify-end items-end">
                  <div className="bg-gray-900 opacity-80 w-1/3 p-3 m-0 border border-warning shadow-md shadow-error ease-in-out duration-300 hover:w-1/2 ">
                    <h2 className="text-2xl font-bold">{slide.title}</h2>
                    <p>{slide.date}</p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>

      {/* Upcoming Events */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full max-w-5xl mt-10 z-0">
        {" "}
        {/* Set z-index lower here */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-warning border-b-2 border-warning pb-2 mb-5">
            Upcoming Events
          </h2>
          {events.map((event, index) => (
            <Link to={"/events/" + event._id} key={index}>
              <div className="bg-white bg-opacity-10 rounded-lg p-5 mb-5 shadow-md hover:scale-105 transition-transform border-y border-warning flex justify-between shadow-error">
                <div className="w-1/6">
                  <img
                    src={event.imgUrl}
                    alt={event.title}
                    className="object-cover object-top w-full h-full"
                  />
                </div>
                <div className="w-5/6 flex flex-col px-4">
                  <p className="text-xs">
                    {new Date(event.date).toLocaleDateString()}
                  </p>
                  <h3 className="text-lg font-bold">{event.title}</h3>
                  <p className="truncate-lines-3 ">{event.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </motion.div>
        {/* Blog Posts */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-warning border-b-2 border-warning pb-2 mb-5">
            BLOG
          </h2>
          {blogPosts.length > 0 && (
            <Link to={"/blog/" + blogPosts[0]._id}>
              <div className="relative h-72 bg-cover bg-center rounded-lg mb-5 shadow-md overflow-hidden hover:bg-opacity-75 transition-opacity shadow-error">
                <img
                  src={
                    blogPosts[0].image || "https://via.placeholder.com/400x400"
                  }
                  alt={blogPosts[0].title}
                  className="object-cover w-full h-full"
                />
                <div className="bg-black bg-opacity-40 hover:bg-opacity-20 p-5 absolute inset-0 flex flex-col justify-end">
                  <p className="text-xs">
                    {formatDate(blogPosts[0].createdAt)}
                  </p>
                  <h3 className="text-xl font-bold">{blogPosts[0].title}</h3>
                  <p>{blogPosts[0].summary}</p>
                </div>
              </div>
            </Link>
          )}
          {blogPosts.slice(1).map((post, index) => (
            <Link to={"/blog/" + post._id} key={index}>
              <div className="bg-white bg-opacity-10 rounded-lg p-5 mb-5 shadow-md hover:scale-105 transition-transform border-y border-warning shadow-error">
                <p className="text-xs">{formatDate(post.createdAt)}</p>
                <h3 className="text-lg font-bold">{post.title}</h3>
                <p>{post.summary}</p>
              </div>
            </Link>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
