import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import background from "./images/home-bg.jpg";
import { useQuery } from "@apollo/client";
import { QUERY_EVENT_BY_ID } from "../utils/queries";

const Event = () => {
  const { eventId } = useParams();
  const { loading, data, error } = useQuery(
    QUERY_EVENT_BY_ID,
    { variables: { eventId: eventId } },
    {
      onCompleted: (data) => console.log("Query completed:", data),
      onError: (error) => console.error("Query error:", error),
    }
  );
  const [ticketCount, setTicketCount] = useState(1);
  let currentTier = 0;

  if (loading) {
    return <h2>...loading</h2>;
  }

  if (error) {
    return <h2>Error: {error.message}</h2>;
  }

  if (!loading) {
    const event = data.eventById;
    const numberOfTixSold = event.ticketsSold.length;

    let cumulativeQuantity = 0;
    let soldOut = true;
    for (let i = 0; i < event.tiers.length; i++) {
      console.log(numberOfTixSold);
      if (event.tiers[i].quantity > 0) {
        currentTier = i;
        soldOut = false;
        break;
      }
    }

    const handleIncrement = () => {
      if (ticketCount < event.tiers[currentTier].quantity && ticketCount < 5) {
        setTicketCount(ticketCount + 1);
      }
    };

    const handleDecrement = () => {
      if (ticketCount > 1) {
        setTicketCount(ticketCount - 1);
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(
        `Purchasing ${ticketCount} tickets from tier ${event.tiers[currentTier].level}`
      );
    };

    return (
      <>
        <div style={{ backgroundImage: `url(${background})` }}>
          <div className="flex flex-col">
            {event.imgUrl ? (
              <div className="flex justify-center mt-5">
                <img
                  className="w-full md:w-1/2 lg:w-1/3"
                  src={event.imgUrl}
                  alt="Event"
                />
              </div>
            ) : null}
            <div className="flex flex-col-reverse md:flex-row lg:flex-row justify-between p-7">
              <h1 className="text-white text-3xl">{event.title}</h1>
              <h2 className="text-white text-3xl">
                {event.date.slice(5, 7) +
                  "." +
                  event.date.slice(8, 10) +
                  "." +
                  event.date.slice(0, 4)}
              </h2>
            </div>
            <p className="text-white text-lg m-6">{event.description}</p>

            <div className="flex flex-col md:flex-row lg:flex-row justify-around text-center">
              {/* Ticket Tiers Section */}
              <div className="m-7">
                <h3 className="text-white text-2xl">Ticket Tiers:</h3>
                {event.tiers.map((tier, index) => {
                  let tierClass = "text-white";
                  if (index < currentTier) {
                    tierClass += " text-gray-400";
                  }
                  if (tier.quantity <= 0) {
                    tierClass += " line-through"; // Add strikethrough if sold out
                  }
                  return (
                    <div key={tier.level} className={tierClass}>
                      <span>
                        {tier.level} - ${tier.price}
                      </span>
                    </div>
                  );
                })}
              </div>

              {/* Conditional "MORE INFORMATION" Button */}
              {event.title === "Invasion" && (
                <div className="flex justify-center mb-7 items-center">
                  <Link
                    to="/invasion"
                    className="pt-3 px-2 bg-error hover:bg-warning text-white border border-warning text-2xl rounded-lg h-1/3 shadow-lg shadow-warning hover:shadow-error"
                  >
                    MORE INFORMATION
                  </Link>
                </div>
              )}

              {/* Current Tier Section */}
              <div className="flex flex-col m-7">
                <h3 className="text-white text-2xl ">
                  Current Tier: {event.tiers[currentTier].level} ($
                  {event.tiers[currentTier].price})
                </h3>
                <p className="text-gray-400 mb-4">
                  {event.tiers[currentTier].quantity} Tickets Remaining at This
                  Tier
                </p>
              </div>
            </div>

            {soldOut ? (
              <div className="text-center text-red-500 font-bold text-2xl my-8">
                This event is sold out
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center mb-7"
              >
                <label className="text-white text-lg">
                  How many tickets are you looking for?
                </label>
                <label className="text-white text-sm mb-4">
                  (5 ticket max)
                </label>
                <div className="flex items-center mb-4">
                  <button
                    type="button"
                    onClick={handleDecrement}
                    className="bg-warning opacity-90 hover:opacity-100 text-white p-3 rounded-full mr-4"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={ticketCount}
                    readOnly
                    className="text-white text-2xl p-3 border rounded-lg text-center w-20"
                  />
                  <button
                    type="button"
                    onClick={handleIncrement}
                    className="bg-warning opacity-90 hover:opacity-100 text-white p-3 rounded-full ml-4"
                  >
                    +
                  </button>
                </div>

                <Link
                  to={
                    "/buy/" +
                    event._id +
                    "/" +
                    (currentTier + 1) +
                    "/" +
                    ticketCount
                  }
                  className="p-2 bg-warning opacity-90 hover:opacity-100 text-white rounded "
                >
                  Purchase
                </Link>
              </form>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Event;
